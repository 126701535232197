import React, { Suspense, useState } from "react";
import {
    Row,
    Col,
    Typography,
    Divider,
} from 'antd';
import {Link} from "gatsby"
import { isMobile } from "react-device-detect";
import TEAMLINKLogoWhite from '../assets/icons/TeamlinkLogoWhite.svg'
import {hBox} from "gx-web-ui/utils/flex";
import { makeStyles } from '@material-ui/core/styles';

import MobileFooter from './mobile/MobileFooter';

import './LandingPageOptions.less'

const columns = {
  'teamlink': [
    <a className="option-link" href="https://developer.teamlink.com.au/" target="_blank" rel="noopener">Developer Login</a>,
    <a className="option-link" href="https://agency.teamlink.com.au/" target="_blank" rel="noopener">Agency Login</a>,
    <a className="option-link" href="https://apps.apple.com/us/app/teamlink-com-au/id1495499958?ls=1" target="_blank" rel="noopener">Download iOS</a>,
    <a className="option-link" href="https://play.google.com/store/apps/details?id=tech.genx.teamlink" target="_blank" rel="noopener">Download Android</a>,
  ],
  'solutions': [
    <a className="option-link" href="https://www.teamlink.com.au/developer/" target="_blank" rel="noopener">For Developer</a>,
    <a className="option-link" href="https://www.teamlink.com.au/agency/" target="_blank" rel="noopener">For Agency</a>,
    <a className="option-link" href="https://www.teamlink.com.au/agent/" target="_blank" rel="noopener">For Agent</a>,
  ],
  'resources': [
    <a className="option-link" href="https://listing.teamlink.com.au/">Project Listing</a>,
    <a className="option-link" href="https://www.teamlink.com.au/wp-content/uploads/2021/10/2021.04.08-Teamlink-privacy-policy.pdf" target="_blank" rel="noopener">Privacy Policy</a>,
    <a className="option-link" href="https://www.teamlink.com.au/wp-content/uploads/2021/10/Terms-Conditions.pdf" target="_blank" rel="noopener">Terms &amp; Conditions</a>,
  ],
  'contact us': [<a className="option-link" href="mailto:info@teamlink.com.au">info@teamlink.com.au</a>],
}

const  LandingPageOptions= (props) => {
  return (
    isMobile? <MobileFooter /> :
    <div style={{ backgroundColor: '#000' }}>
      <Row className="option-container">
        <Col xs={24} sm={24} md={12}>
          <Link style={{...hBox('center')}} to="https://www.teamlink.com.au">
            <img src={TEAMLINKLogoWhite} style={{'height' : 28, 'width' : 'auto'}} />
          </Link>
          <Typography.Paragraph style={{ fontSize: 14, color: '#fff', marginTop: 20 }}>Level 4, 161 Redfern St {<br />}Redfern, NSW 2016</Typography.Paragraph>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Row>
            {
              Object.keys(columns).map(key => {
                return (
                  <Col key={key} span={6}>
                    <Typography.Paragraph style={{ fontSize: 12, color: '#fff', fontWeight: 'bold' }}>
                      {key.toUpperCase()}
                    </Typography.Paragraph>
                    {
                      columns[key].map((link, idx) => <p key={idx} style={{ fontSize: 12, color: '#fff' }}>{link}</p>)
                    }
                  </Col>
                )
              })
            }
          </Row>
          <Divider style={{ height: 1, background: '#fff', marginTop: 6, marginBottom: 24 }} />
          <a href="https://apps.apple.com/us/app/teamlink-com-au/id1495499958?ls=1" target="_blank">
            <img width="119" height="40" style={{ marginRight: 20 }} src="https://www.teamlink.com.au/wp-content/uploads/2021/10/Download_App_Store_Badge_US.svg" className="attachment-large size-large" alt="" loading="lazy"></img>
          </a>
          <a href="https://play.google.com/store/apps/details?id=tech.genx.teamlink" target="_blank">
            <img width="135" height="40" src="https://www.teamlink.com.au/wp-content/uploads/2021/10/Group-240.svg" className="attachment-large size-large" alt="" loading="lazy"></img>
          </a>
        </Col>
      </Row>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    maxWidth: 1315,
    margin: 'auto',
    color: '#fff',
    paddingTop: 60,
    paddingBottom: 60,
  },
}),{index:1});

export default LandingPageOptions