import React, { useState } from "react";
import { Col, Row, Typography } from "antd";
import {Link} from "gatsby";
import {hBox} from "gx-web-ui/utils/flex";

import TEAMLINKLogoWhite from '../../assets/icons/TeamlinkLogoWhite.svg'

const columns = {
  'teamlink': [
    <a className="option-link" href="https://developer.teamlink.com.au/" target="_blank" rel="noopener">Developer Login</a>,
    <a className="option-link" href="https://agency.teamlink.com.au/" target="_blank" rel="noopener">Agency Login</a>,
    <a className="option-link" href="https://apps.apple.com/us/app/teamlink-com-au/id1495499958?ls=1" target="_blank" rel="noopener">Download iOS</a>,
    <a className="option-link" href="https://play.google.com/store/apps/details?id=tech.genx.teamlink" target="_blank" rel="noopener">Download Android</a>,
  ],
  'solutions': [
    <a className="option-link" href="https://www.teamlink.com.au/developer/" target="_blank" rel="noopener">For Developer</a>,
    <a className="option-link" href="https://www.teamlink.com.au/agency/" target="_blank" rel="noopener">For Agency</a>,
    <a className="option-link" href="https://www.teamlink.com.au/agent/" target="_blank" rel="noopener">For Agent</a>,
  ],
  'resources': [
    <a className="option-link" href="https://listing.teamlink.com.au/">Project Listing</a>,
    <a className="option-link" href="https://www.teamlink.com.au/wp-content/uploads/2021/10/2021.04.08-Teamlink-privacy-policy.pdf" target="_blank" rel="noopener">Privacy Policy</a>,
    <a className="option-link" href="https://www.teamlink.com.au/wp-content/uploads/2021/10/Terms-Conditions.pdf" target="_blank" rel="noopener">Terms &amp; Conditions</a>,
  ],
}

const MobileFooter = (props) => {
  return (
    <div className="m-footer">
      <div className="logo-address">
        <Link style={{...hBox('center')}} to="https://www.teamlink.com.au">
          <img src={TEAMLINKLogoWhite} className="logo" />
        </Link>
        <Typography.Paragraph style={{ fontSize: 12, color: '#fff'}}>Level 4, 161 Redfern St {<br />}Redfern, NSW 2016</Typography.Paragraph>
      </div>
      <Row>
      {
        Object.keys(columns).map(key => {
          return (
            <Col key={key} span={8}>
              <Typography.Paragraph style={{ fontSize: 12, color: '#fff', fontWeight: 'bold' }}>
                {key.toUpperCase()}
              </Typography.Paragraph>
              {
                columns[key].map((link, idx) => <p key={idx} style={{ fontSize: 12, color: '#fff' }}>{link}</p>)
              }
            </Col>
          )
        })
      }
      </Row>
    </div>
  )
};

export default MobileFooter;